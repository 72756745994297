<template>
  <div>
    <page-header title='Agenda' class="mb-2"></page-header>
    <v-row justify="end" class="mb-2">
      <v-btn color="white" depressed rounded class="mx-2 mr-10" @click="$router.push({name: 'NewCourse'})"> 
        <v-icon class="mr-1" size="26" color="primary">mdi-plus-circle-outline</v-icon> 
        NOVO CURSO
      </v-btn>
    </v-row>
    
    <v-row align="stretch" >

      <v-col cols="12" lg="7" class="pt-0">
        <course-list v-if="user.role.name == 'student'" :use_with_emit="true" @courseDetails="handleSelectedCourse"></course-list>
        <course-calendar v-if="user.role.name != 'student'" :use_with_emit="true" @selectedCourse="handleSelectedCourse"></course-calendar>
      </v-col>

      <v-col cols="12" lg="5" class="pt-0">
        <v-card outlined style='border-radius: 18px;' v-if="!Object.keys(this.course_item).length != 0">
          <v-card-text>
            <div class="grey--text title">
              Selecione um curso para exibir detalhes.
            </div>
          </v-card-text>
        </v-card>

        <v-card outlined style='border-radius: 18px;' v-if="Object.keys(this.course_item).length != 0">
          <v-card-title class="primary white--text" >
            Detalhes do curso
            <v-spacer></v-spacer>
            <v-btn text class="ml-2" icon color="white" @click="closeCardDetails()" rounded><v-icon>mdi-close</v-icon></v-btn>
          </v-card-title>

          <card-loading-transparent loadingText="Carregando" v-if="loading"/>

          <v-card-text v-if="!loading" :style="responsivity_vh">
            <course-details :selected_course="course_item"/>
          </v-card-text>

          <v-divider v-if="!loading"/>
          <v-card-actions v-if="!loading">
            <selected-course-card-actions :selected_course="course_item"  @closeDialog="handleClose"></selected-course-card-actions>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import { mapActions, mapState, mapGetters } from 'vuex'
  export default {
    name: 'CourseIndexAgenda',

    components: {
      CourseList: () => import('./list/CourseList.vue'),
      CourseCalendar: () => import('./agenda/CourseCalendar.vue'),
      SelectedCourseCardActions: () => import('./shared/SelectedCourseCardActions.vue'),
      CourseDetails: () => import('./CourseDetails.vue'),
    },
    
    data() {
      return {
        course_item: {},
        selected_dialog: false,
        loading: false,

      }
    },
    computed: {
      responsivity_vh(){
        return this.$vuetify.breakpoint.lg ? 
        'max-height: 57.5vh ; overflow-y: auto' : 
        this.$vuetify.breakpoint.smAndDown ? 
        'max-height: 57.5vh ; overflow-y: auto' : 
        'max-height: 57.8vh ; overflow-y: auto'
      },
      ...mapState({
        user: state=> state.User.user
      })
    },
    methods: {

      handleSelectedCourse(e) {
        this.getData()
        this.course_item = e
        // setTimeout(() => {
        //   window.scrollTo({ left: 0, top: document.body.scrollHeight, behavior: "smooth" });
        // }, 200);
      },
      handleClose(){
        window.scrollTo({ left: 0, top: 0, behavior: "smooth" });
        setTimeout(() => {
          this.course_item = {}
        }, 400);
      },
      closeCardDetails(){
        this.course_item = {}
        this.getData()
      },

      getData(){
        this.loading = true
        setTimeout(() => {
          this.loading = false
        }, 500);
      },
    },

  }
</script>

<style lang="scss" scoped>

</style>